import wizardConfigs from './wizard-configs';
import { customServices } from './custom-pages.config';
const { pages } = wizardConfigs;

const UI_BASE_URL = 'https://rd-trading.net';

export const product = pages.product.previewPath ? UI_BASE_URL + pages.product.previewPath : null; // '/product')

export const news = pages.newsIndex.previewPath ? UI_BASE_URL + pages.newsIndex.previewPath : null; // '/news')

export const service = pages.service.previewPath ? UI_BASE_URL + pages.service.previewPath : null; // '/service')

export const customService =
	customServices.map(c => ({
		type: c.type,
		previewUrl: c.previewPath ? UI_BASE_URL + c.previewPath : null,
	}));